import React from "react";

const Timeline = () => {
  return (
    <section
      id="timeline"
      className="my-5 p-4 container shadow-lg reveal-bottom scroll-margin"
    >
      <div className="row my-5">
        <div className="col-lg-8 mx-auto text-center text-muted">
          <h2 className="topic display-6 section-heading text-white text-uppercase">
            IESL RoboGames 2024 Event Timeline
          </h2>
          <hr />
        </div>
      </div>

      {/* first section */}
      <div className="row align-items-center how-it-works d-flex">
        <div className="col-2 text-center bottom d-inline-flex justify-content-center align-items-center">
          <div className="circle font-weight-bold">1</div>
        </div>
        <div className="col-6 text-muted reveal-left">
          {/* <h5 className="display-6">
            15<sup>th</sup> October
          </h5> */}
          <h5 className="display-6">Registration Opens</h5>
          {/* <h7 className="display-6">
          Registration Opens
          </h7> */}
          <p>Registrations Opens</p>
        </div>
      </div>
      {/* path between 1-2 */}
      <div className="row timeline">
        <div className="col-2">
          <div className="corner top-right"></div>
        </div>
        <div className="col-8">
          <hr />
        </div>
        <div className="col-2">
          <div className="corner left-bottom"></div>
        </div>
      </div>

      {/* second section */}
      <div className="row align-items-center justify-content-end how-it-works d-flex">
        <div className="col-6 text-muted reveal-left">
          {/*<h5 className="display-6">
            25<sup>th</sup> October
          </h5>*/}
          <h5 className="display-6">Introductory Session IESL RoboGames'24</h5>
          {/* <h6 className="display-6">
            Introductory Session IESL RoboGames'24
          </h6> */}
          <p>
            Introductory Session is aimed at promoting innovation and robotics
            skills among participants. This session will outline the event
            roadmap, detailing key dates and competition stages, as well as the
            selection criteria for advancement.
          </p>
        </div>
        <div className="col-2 text-center full d-inline-flex justify-content-center align-items-center">
          <div className="circle font-weight-bold">2</div>
        </div>
      </div>
      {/* path between 2-3 */}
      <div className="row timeline">
        <div className="col-2">
          <div className="corner right-bottom"></div>
        </div>
        <div className="col-8">
          <hr />
        </div>
        <div className="col-2">
          <div className="corner top-left"></div>
        </div>
      </div>

      {/* third section */}
      <div className="row align-items-center how-it-works d-flex">
        <div className="col-2 text-center top d-inline-flex justify-content-center align-items-center">
          <div className="circle font-weight-bold">3</div>
        </div>
        <div className="col-6  text-muted">
          {/* <h5 className="display-6 reveal-left">
            30<sup>th</sup> October
          </h5> */}
          <h5 className="display-6 reveal-left">Closing Registration</h5>
          {/* <h5 className="display-6 reveal-left">
            30<sup>th</sup> October
          </h5> */}
          {/* <p>Closing Registration</p> */}
        </div>
      </div>
      {/* path between 3-4 */}
      <div className="row timeline">
        <div className="col-2">
          <div className="corner top-right"></div>
        </div>
        <div className="col-8">
          <hr />
        </div>
        <div className="col-2">
          <div className="corner left-bottom"></div>
        </div>
      </div>

      {/* fourth section */}
      <div className="row align-items-center justify-content-end how-it-works d-flex">
        <div className="col-6 text-end text-muted reveal-right">
          {/*<h5 className="display-6">
            11<sup>th</sup> November
          </h5>*/}
          <h5 className="display-6">Basic Webot Session</h5>
          {/* <h6 className="display-6">
            Basic Webot Session
          </h6> */}
          <p>
            Following the closure of registration and before the first-round
            submission, an online Basic Webot Session will be conducted. This
            session will provide participants with essential skills in coding,
            programming, and debugging concepts on the Webot platform.
          </p>
        </div>
        <div className="col-2 text-center full d-inline-flex justify-content-center align-items-center">
          <div className="circle font-weight-bold">4</div>
        </div>
      </div>
      <div className="row timeline">
        <div className="col-2">
          <div className="corner right-bottom"></div>
        </div>
        <div className="col-8">
          <hr />
        </div>
        <div className="col-2">
          <div className="corner top-left"></div>
        </div>
      </div>

      {/* fifth section */}
      <div className="row align-items-center how-it-works d-flex">
        <div className="col-2 text-center top d-inline-flex justify-content-center align-items-center">
          <div className="circle font-weight-bold">5</div>
        </div>
        <div className="col-6  text-muted">
          {/* <h5 className="display-6 reveal-left">
            25<sup>th</sup> November
          </h5> */}
          <h5 className="display-6 reveal-left">First Round</h5>
          {/* <h5 className="display-6 reveal-left">
            25<sup>th</sup> November
          </h5>
          <p>First Round</p> */}
        </div>
      </div>
      {/* path between 5-6 */}
      <div className="row timeline">
        <div className="col-2">
          <div className="corner top-right"></div>
        </div>
        <div className="col-8">
          <hr />
        </div>
        <div className="col-2">
          <div className="corner left-bottom"></div>
        </div>
      </div>

      {/* sixth section */}
      <div className="row align-items-center justify-content-end how-it-works d-flex">
        <div className="col-6  text-muted">
          {/*<h5 className="display-6 reveal-left">
            10<sup>th</sup> December
          </h5>*/}
          <h5 className="display-6 reveal-left">
            Physical Advanced Webot Session
          </h5>
          {/*<h5 className="display-6 reveal-left">
            10<sup>th</sup> December
          </h5>*/}
          <p>
            Participants who emerge as winners from the first round will be
            invited to a Physical Advanced Webot Session prior to the
            second-round submission. This hands-on experience will enhance their
            practical skills and deepen their understanding of the Webot
            platform.
          </p>
        </div>
        <div className="col-2 text-center full d-inline-flex justify-content-center align-items-center">
          <div className="circle font-weight-bold">6</div>
        </div>
      </div>
      <div className="row timeline">
        <div className="col-2">
          <div className="corner right-bottom"></div>
        </div>
        <div className="col-8">
          <hr />
        </div>
        <div className="col-2">
          <div className="corner top-left"></div>
        </div>
      </div>

      {/* seventh section */}
      <div className="row align-items-center how-it-works d-flex">
        <div className="col-2 text-center top d-inline-flex justify-content-center align-items-center">
          <div className="circle font-weight-bold">7</div>
        </div>
        <div className="col-6  text-muted">
          {/* <h5 className="display-6 reveal-left">
            27<sup>th</sup> December
          </h5> */}
          <h5 className="display-6 reveal-left">Semi Finals</h5>
          {/* <h5 className="display-6 reveal-left">
            27<sup>th</sup> December
          </h5> */}
          {/* <p>Semi Finals</p> */}
        </div>
      </div>
      {/* path between 7-8 */}
      <div className="row timeline">
        <div className="col-2">
          <div className="corner top-right"></div>
        </div>
        <div className="col-8">
          <hr />
        </div>
        <div className="col-2">
          <div className="corner left-bottom"></div>
        </div>
      </div>

      {/* eighth/last section */}
      <div className="row align-items-center justify-content-end how-it-works d-flex">
        <div className="col-6 text-end text-muted">
          {/*<h5 className="display-6 reveal-right">
            11<sup>th</sup> January
          </h5>*/}
          <h5 className="display-6 reveal-right">Kobuki Workshop</h5>
          {/*<h5 className="display-6 reveal-right">
            11<sup>th</sup> January
          </h5>*/}
          {/*<h5 className="display-6 reveal-right">
            11<sup>th</sup> January
          </h5>*/}
          <p>
            Finalists will have the opportunity to attend a Kobuki Workshop,
            where they can get hands-on activities with Kobuki Robots specially
            designed at the University of Moratuwa. They will complete special
            tasks, gaining invaluable experience using these robots and can
            prepare for the final competition round.
          </p>
        </div>
        <div className="col-2 text-center full d-inline-flex justify-content-center align-items-center">
          <div className="circle font-weight-bold">8</div>
        </div>
      </div>

      {/* 8-9 Path */}
      <div className="row timeline">
        <div className="col-2">
          <div className="corner right-bottom"></div>
        </div>
        <div className="col-8">
          <hr />
        </div>
        <div className="col-2">
          <div className="corner top-left"></div>
        </div>
      </div>

      {/* nineth section */}
      <div className="row align-items-center how-it-works d-flex">
        <div className="col-2 text-center top d-inline-flex justify-content-center align-items-center">
          <div className="circle font-weight-bold">9</div>
        </div>
        <div className="col-6  text-muted">
          {/* <h5 className="display-6 reveal-left">
            27<sup>th</sup> January
          </h5> */}
          <h5 className="display-6 reveal-left">Finals</h5>
          {/* <h5 className="display-6 reveal-left">
            27<sup>th</sup> January
          </h5> */}
          {/* <p>Finals</p> */}
        </div>
      </div>

      {/* <div className="row timeline">
        <div className="col-2">
          <div className="corner top-right"></div>
        </div>
      </div> */}

      {/* path between 7-8 */}
      {/* <div className="row timeline">
        <div className="col-2">
          <div className="corner top-right"></div>
        </div>
        <div className="col-8">
          <hr />
        </div>
        <div className="col-2">
          <div className="corner left-bottom"></div>
        </div>
      </div> */}

      {/* path between 4-5 */}
      {/* <div className="row timeline">
        <div className="col-2">
          <div className="corner right-bottom"></div>
        </div>
        <div className="col-8">
          <hr />
        </div>
        <div className="col-2">
          <div className="corner top-left"></div>
        </div>
      </div> */}
      {/* fifth section */}
      {/* <div className="row align-items-center how-it-works d-flex">
        <div className="col-2 text-center top d-inline-flex justify-content-center align-items-center">
          <div className="circle font-weight-bold">5</div>
        </div>
        <div className="col-6  text-muted">
          <h5 className="display-6 reveal-left">
            14<sup>th</sup> November
          </h5>
          <p>Issue the Initial Challenge</p>
          <small>
            This is an offline challenge given to the participants to get an
            idea about the upcoming competition. They will get a chance to
            submit their solution and depending on the feedback they can refine
            their solution. This will not be evaluated.
          </small>
        </div>
      </div> */}
      {/* path between 5-6 */}
      {/* <div className="row timeline">
        <div className="col-2">
          <div className="corner top-right"></div>
        </div>
        <div className="col-8">
          <hr />
        </div>
        <div className="col-2">
          <div className="corner left-bottom"></div>
        </div>
      </div> */}
      {/* sixth section */}
      {/* <div className="row align-items-center justify-content-end how-it-works d-flex">
        <div className="col-6 text-end text-muted">
          <h5 className="display-6 reveal-right">
            21<sup>st</sup> November
          </h5>
          <p>Mid Feedback Session</p>
          <small></small>
        </div>
        <div className="col-2 text-center full d-inline-flex justify-content-center align-items-center">
          <div className="circle font-weight-bold">6</div>
        </div>
      </div> */}
      {/* path between 6-7 */}
      {/* <div className="row timeline">
        <div className="col-2">
          <div className="corner right-bottom"></div>
        </div>
        <div className="col-8">
          <hr />
        </div>
        <div className="col-2">
          <div className="corner top-left"></div>
        </div>
      </div> */}
      {/* seventh section */}
      {/* <div className="row align-items-center how-it-works d-flex">
        <div className="col-2 text-center top d-inline-flex justify-content-center align-items-center">
          <div className="circle font-weight-bold">7</div>
        </div>
        <div className="col-6  text-muted">
          <h5 className="display-6 reveal-left">
            28<sup>th</sup> November
          </h5>
          <p>Final Feedback Session</p>
          <small></small>
        </div>
      </div> */}
      {/* path between 7-8 */}
      {/* <div className="row timeline">
        <div className="col-2">
          <div className="corner top-right"></div>
        </div>
        <div className="col-8">
          <hr />
        </div>
        <div className="col-2">
          <div className="corner left-bottom"></div>
        </div>
      </div> */}
      {/* eighth section */}
      {/* <div className="row align-items-center justify-content-end how-it-works d-flex">
        <div className="col-6 text-end text-muted">
          <h5 className="display-6 reveal-right">
            29<sup>st</sup> November
          </h5>
          <p>Issue the Final Challenge</p>
          <small></small>
        </div>
        <div className="col-2 text-center full d-inline-flex justify-content-center align-items-center">
          <div className="circle font-weight-bold">8</div>
        </div>
      </div> */}
      {/* path between 8-9 */}
      {/* <div className="row timeline">
        <div className="col-2">
          <div className="corner right-bottom"></div>
        </div>
        <div className="col-8">
          <hr />
        </div>
        <div className="col-2">
          <div className="corner top-left"></div>
        </div>
      </div> */}
      {/* ninth section */}
      {/* <div className="row align-items-center how-it-works d-flex">
        <div className="col-2 text-center top d-inline-flex justify-content-center align-items-center">
          <div className="circle font-weight-bold">9</div>
        </div>
        <div className="col-6  text-muted">
          <h5 className="display-6 reveal-left">
            08<sup>th</sup> December
          </h5>
          <p>IESL RoboGames Challenge 2022</p>
          <small></small>
        </div>
      </div> */}
      {/* path between 9-10 */}
      {/* <div className="row timeline">
        <div className="col-2">
          <div className="corner top-right"></div>
        </div>
        <div className="col-8">
          <hr />
        </div>
        <div className="col-2">
          <div className="corner left-bottom"></div>
        </div>
      </div> */}
      {/* tenth section */}
      {/* <div className="row align-items-center justify-content-end how-it-works d-flex">
        <div className="col-6 text-end text-muted">
          <h5 className="display-6 reveal-right">TBD</h5>
          <p>Awarding Ceremony</p>
          <small></small>
        </div>
        <div className="col-2 text-center full d-inline-flex justify-content-center align-items-center">
          <div className="circle font-weight-bold">10</div>
        </div>
      </div> */}
    </section>
  );
};

export default Timeline;
