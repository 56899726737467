import React from "react";
import { images } from "../javascript/imageImports";

const Contact = () => {
  return (
    <section id="contact" className="my-5 p-4 scroll-margin">
      <div className="container text-center reveal-bottom">
        <div className="row text-muted">
          <hr className="my-4" />
          <div className="col-sm-8 col-md-6 mx-0 text-start">
            <p className="lead text-muted my-4">
              For further inquiries and clarifications, feel free to contact us.
            </p>
            <h2 className="topic display-6 section-heading text-white">
              Contact Us
            </h2>
          </div>

          <div className="col-sm-8 col-md-6 mx-0 my-4 text-start">
            <address className="lead text-warning">
              Department of Computer Science and Engineering,
              <br />
              University of Moratuwa,
              <br />
              Katubedda,
              <br />
              Sri Lanka.
            </address>

            <div className="icons my-4 d-flex">
              <a
                href="https://www.facebook.com/pg/ieslrobogame"
                className="me-4"
              >
                <i className="fab fa-facebook-f"></i>
              </a>
              {/* <a href="mailto:rob@cse.mrt.ac.lk" className="me-4">
                <i className="fas fa-envelope"></i>
              </a> */}
              {/* <a href="https://wa.me/+94766318625" className="me-4">
                <i className="fab fa-whatsapp"></i>
              </a> */}
            </div>
          </div>

          <div className="footer">
            <div>
              <a
                style={{ fontSize: "20px" }}
                href="mailto:mailtonativecode@gmail.com"
                target="_blank"
                rel="noreferrer"
                className="text-decoration-none"
              >
                Developed by NativeCode
              </a>
            </div>
            <br />
            {/* <span>
              Powered by{" "}
              <img
                src={images.slt}
                alt="SLT"
                className="img-fluid fa-file-image ms-4"
                style={{ height: "50px", width: "130px" }}
              />
            </span> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
