import React, { useState } from "react";
import { images } from "../javascript/imageImports";

const About = () => {
  const [sliderData, setSliderData] = useState(50,990,0);

  const handleMouseMove = (e) => {
    let delta = (e.clientX - window.innerWidth / 2) * 0.5;
    let skew = 990;
    let xPos = e.clientX;

    // setSliderData({ xPos, delta, skew });
  };

  return (
    <section id="about" className="my-5 p-4 scroll-margin">
      <section id="wrapper" className="skewed" onMouseMove={handleMouseMove}>
        <div className="layer bottom-section">
          <div className="content-wrap">
            <div className="content-body mx-lg-4 p-4">
              <h2 className="topic display-6 section-heading text-muted text-uppercase reveal-right">
                Purpose
              </h2>
              <hr className="light my-4" />

              <p className="my-4 reveal-right text-muted">
                Most of the Sri Lankan students are interested in robotics
                technology the main constraint for them is lack of knowledge and
                facilities. Only very few schools have well equipped ICT
                laboratories, electronic equipment and resources persons. Main
                idea of organizing this event is to provide assistance for
                students to learn the art of robot development and ultimately
                give them an opportunity to make use of what they have learnt
                and showcase their talents.
              </p>
            </div>
            <img className="optimusprime"  src={images.optimus} alt="pc1" />
          </div>
        </div>

        <div
          className="layer top-section"
          style={{
            width: sliderData.xPos + sliderData.skew + sliderData.delta + "px",
          }}
        >
          <div className="content-wrap">
            <div className="content-body mx-lg-4">
              <div className="shape-optimus"></div>
              <h2 className="topic display-6 section-heading text-dark text-uppercase reveal-left">
                About IESL Robogames
              </h2>
              <hr className="light my-4" />
              <p className="my-4 reveal-left">
                &quot;IESL RoboGames&quot;stands as an annual event orchestrated
                by the Information Technology and Communications Engineering
                Sectional Committee of the Institution of Engineers, Sri Lanka,
                in partnership with the Department of Computer Science and
                Engineering at the University of Moratuwa. The primary goal is
                to foster and acknowledge the aptitude and proficiencies of the
                upcoming generation of professionals. The competition is divided
                into two segments: the School Category and the Undergraduate
                Category. In the School Category, a sequence of workshops and
                informative initiatives will be conducted, aimed at introducing
                the realm of robotics into educational practices within schools.
                This initiative not only serves as a platform to nurture
                creativity but also enhances learning efficacy, motivating young
                minds to aspire to become future engineers and innovators.
              </p>
            </div>
            <img className="optimusprime" src={images.optimus} alt="pc1" />
          </div>
        </div>

        <div
          className="handle-one"
          style={{ left: sliderData.xPos + sliderData.delta + "px" }}
        ></div>
        <div
          className="handle-two"
          style={{ left: sliderData.xPos + sliderData.delta - 10 + "px" }}
        ></div>
      </section>

      <div className="container d-md-none">
        <div className="row mt-5">
          <div className="col-lg-8 mx-auto text-center text-muted reveal-bottom">
            <h2 className="topic display-6 section-heading text-white text-uppercase">
              About IESL Robogames
            </h2>
            <hr className="light my-4" />
            <p className="my-4">
              &quot;IESL RoboGames&quot;stands as an annual event orchestrated
              by the Information Technology and Communications Engineering
              Sectional Committee of the Institution of Engineers, Sri Lanka, in
              partnership with the Department of Computer Science and
              Engineering at the University of Moratuwa. The primary goal is to
              foster and acknowledge the aptitude and proficiencies of the
              upcoming generation of professionals. The competition is divided
              into two segments: the School Category and the Undergraduate
              Category. In the School Category, a sequence of workshops and
              informative initiatives will be conducted, aimed at introducing
              the realm of robotics into educational practices within schools.
              This initiative not only serves as a platform to nurture
              creativity but also enhances learning efficacy, motivating young
              minds to aspire to become future engineers and innovators.
            </p>

            <h2 className="topic display-6 section-heading text-white text-uppercase">
              Purpose
            </h2>
            <hr className="light my-4" />
            <p className="my-4">
              The allure of robotics technology captivates many Sri Lankan
              students; however, a prevailing challenge remains their limited
              access to knowledge and resources. Only a handful of schools boast
              adequately equipped ICT laboratories, electronic tools, and
              knowledgeable mentors. The core objective behind arranging this
              event is to bridge this gap by offering students valuable guidance
              in mastering the craft of robot development. This endeavor not
              only imparts essential skills but also empowers them to translate
              their newfound knowledge into tangible creations, affording them a
              platform to display their exceptional talents.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
