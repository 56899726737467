import React from "react";
//import { Link } from "react-router-dom";
import { images } from "../javascript/imageImports";

const Header = () => {
  return (
    <header
      className="text-center text-white d-flex min-vh-100"
      style={{ backgroundImage: `url(${images.background})` }}
    >
      <div className="container mt-5">
        <div className="row">
          <div className="col-10 col-lg-8 mx-auto my-5">
            <h1 className="topic text-uppercase display-1">
              IESL RoboGames 2024
            </h1>
            {/* <span>
              Powered by{" "}
              <img
                src={images.slt}
                alt="SLT"
                className="img-fluid fa-file-image ms-4"
                style={{ height: "50px", width: "130px" }}
              />
            </span> */}
          </div>
          <div className="col-10 col-lg-8 mx-auto my-3">
            <figure>
              <blockquote className="blockquote">
                <p className="my-2">
                  Embrace the challenges, fuel your passion, and let
                  determination be the spark that powers your journey to victory
                  in RoboGames 2024.
                </p>
              </blockquote>
            </figure>
            {/* <Link
              className="neon-button btn btn-outline-warning p-3 px-5 my-5 btn-background"
              to="/goLive"
            >
              Go Live!
            </Link> */}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
